/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { Link } from "gatsby";

import { AppContext } from "~context/AppContext";

import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class LinkTreePageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setNavText(`Made up, not made up.`);
  }

  //

  render() {
    return (
      <>
        <SEO pageTitle="Link Tree" pathName="/linktree" />

        <Layout className="linktree-page w-full bg-sun-yellow gpu">
          <header className="header w-full fixed z-40 bg-sun-yellow menu-active py-6">
            <div className="header__bg bg-sun-yellow fixed w-full top-0 left-0 right-0 z-20"></div>

            <nav className="relative grid z-20">
              <div className="animation-appear grid-end-10">
                <Link to="/">
                  <h2 className="header__title f2">
                    <span className="font-bold">Fluff </span>
                    {(this.props.appContext.menuActive && (
                      <span className="font-medium">is:</span>
                    )) || <span className="font-bold">Casual Cosmetics™</span>}
                  </h2>

                  {this.props.appContext &&
                    this.props.appContext.scrollTop <
                      this.headerCollapseHeight && (
                      <h3 className="header__headline f2 font-medium">
                        {this.props.appContext.navText}
                      </h3>
                    )}
                </Link>
              </div>
            </nav>

            <div className="header__overlay w-screen h-screen absolute top-0 right-0 bottom-0 left-0 z-10 flex flex-col justify-between bg-sun-yellow">
              <ul className="header__overlay__links px-6 xs:px-4 pt-16 relative f1 font-medium pb-24">
                <li
                  className="header__overlay__link relative pt-8 xs:pt-0 animation-appear"
                  style={{ animationDelay: `150ms` }}
                >
                  <Link
                    to="/products"
                    className="relative block py-6"
                    onClick={() => {
                      this.props.appContext.setMenuActive(false);
                    }}
                  >
                    <span className="block">Shop Casual Cosmetics™</span>
                  </Link>
                </li>

                <li
                  className="header__overlay__link relative animation-appear"
                  style={{ animationDelay: `250ms` }}
                >
                  <a
                    href="https://issues.itsallfluff.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="relative block py-6"
                    onClick={() => {
                      this.props.appContext.setMenuActive(false);
                    }}
                  >
                    <span className="block">Read Issues</span>
                  </a>
                </li>

                <li
                  className="header__overlay__link relative animation-appear"
                  style={{ animationDelay: `300ms` }}
                >
                  <Link
                    to="/makeunder"
                    className="relative block py-6"
                    onClick={() => {
                      this.props.appContext.setMenuActive(false);
                    }}
                  >
                    <span className="block">Book a Makeunder®</span>
                  </Link>
                </li>

                <li
                  className="header__overlay__link relative animation-appear"
                  style={{ animationDelay: `250ms` }}
                >
                  <Link
                    to="/about"
                    className="relative block py-6"
                    onClick={() => {
                      this.props.appContext.setMenuActive(false);
                    }}
                  >
                    <span className="block">About Us</span>
                  </Link>
                </li>
              </ul>

              <div
                className="animation-appear"
                style={{ animationDelay: `300ms` }}
              >
                <Footer menu />
              </div>
            </div>
          </header>
        </Layout>
      </>
    );
  }
}

const LinkTreePage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <LinkTreePageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default LinkTreePage;
